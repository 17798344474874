<template>
  <div class="container">
    <BaseH1 :text="'Dodaj zdjęcia do galerei'" />

    <div class="wrapper container__wrapper">
      <AttachmentPhotoGallery
        :images="images"
        @pull:image="$emit('pull:image', $event)"
        @push:image="$emit('push:image', $event)"
      />
      <text-hint
        >Do 7 zd. Do 20 mb karzdoje.<br />Możesz dodać zdjęcia swoich
        certyfikatów, rekomendacji, jak również zdjęcia, które prezentują Ciebie
        jako osobę.</text-hint
      >
    </div>
  </div>
</template>

<script>
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextHint from "@/components/UI/TextHint.vue";
import AttachmentPhotoGallery from "@/components/AttachmentPhotoGallery.vue";

export default {
  components: {
    BaseH1,
    TextHint,
    AttachmentPhotoGallery,
  },

  props: {
    images: Array,
  },

  emits: ["pull:image", "push:image"],
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.container__wrapper {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 40px;
}

@media (max-width: 1200px) {
}
</style>
