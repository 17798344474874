<template>
  <div class="container-hint">
    <div class="asterisks container-hint__asterisks">* * *</div>
    <base-text class="hint container-hint__hint"
      ><slot>{{ text }}</slot></base-text
    >
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.container-hint {
  width: 350px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 0px;
}
.container-hint__asterisks {
  font: 300 24px "K2D";
  color: rgba(254, 136, 28, 1);
}
.container-hint__hint {
  font: 300 16px "K2D";
  text-align: center;
}
</style>
