<template>
  <div class="content">
    <ApplicationNannyInputPhotoGallery
      :images="applicationNanny.details.photoGallery"
      @pull:image="pullImage($event)"
      @push:image="pushImage($event)"
    />

    <ButtonBasicNext @click="handleNext()" />
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ApplicationNannyInputPhotoGallery from "@/components/Application/Nanny/ApplicationNannyInputPhotoGallery.vue";
import ButtonBasicNext from "@/components/UI/ButtonBasicNext.vue";
import { ROUTE_APPLICATION_NANNY_CREATE_NAME } from "@/router/constants";

export default {
  components: {
    ApplicationNannyInputPhotoGallery,
    ButtonBasicNext,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();
    const router = useRouter();

    // GUARD -> redirect to the Start if no _id provided
    if (applicationNanny._id === null) {
      router.push({ name: ROUTE_APPLICATION_NANNY_CREATE_NAME });
    }

    const pushImage = async (image) => {
      try {
        // Push image to photo gallery
        await applicationNanny.pushImageToPhotoGallery(image);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const pullImage = async (image) => {
      try {
        // Pull image from photo gallery
        await applicationNanny.pullImageFromPhotoGallery(image);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const handleNext = () => {
      // TODO: if image is loading DO NOT ALLOW to use router!!!!!!!!
      router.push({ name: "ApplicationNannyCreateRecommendations" });
    };

    return {
      pullImage,
      pushImage,
      handleNext,
      applicationNanny,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
@media (max-width: 1200px) {
}
</style>
