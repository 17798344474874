<template>
  <ButtonBasic :label="label" :to="to" :size="size" :isDisabled="isDisabled" />
</template>

<script>
import ButtonBasic from "./Base/ButtonBasic.vue";

export default {
  components: { ButtonBasic },

  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    label: {
      type: String,
      default: "Dalej",
    },
    size: {
      type: String,
      default: "medium",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
