<template>
  <div
    ref="previewRef"
    class="container-preview container-preview--columns"
    :class="images.length === 0 ? 'container-preview--empty' : ''"
  >
    <transition-group name="previews">
      <div
        v-for="img in images"
        :key="img.originalFilename"
        class="wrapper-preview container-preview__wrapper-preview"
      >
        <img
          :src="img.src ?? img.srcTemporary"
          class="img wrapper-preview__img"
        />
        <BaseButtonIconCross
          @click="pullImage(img)"
          class="delete-button wrapper-preview__delete-button"
        />
      </div>
    </transition-group>

    <div
      v-if="images.length < IMG_REQUIREMENTS.maxAmount"
      class="button-wrapper container-preview__button-wrapper"
    >
      <ButtonAttachment
        @click="showFileChooser()"
        :small="false"
        class="attachment-button container-preview__attachment-button"
      />
    </div>
  </div>

  <input
    ref="imgInputRef"
    type="file"
    name="main-photo"
    accept="image/*"
    class="main-photo-input"
    @change="handleImagesInput($event, IMG_REQUIREMENTS)"
    multiple
  />
</template>

<script>
import { ref } from "vue";
import {
  getFilenameExcludingExtension,
  prepareCanvasFromImageFile,
} from "@/helpers";

import ButtonAttachment from "./UI/Base/ButtonAttachment.vue";
import BaseButtonIconCross from "./UI/Base/BaseButtonIconCross.vue";

export default {
  components: { ButtonAttachment, BaseButtonIconCross },

  props: {
    images: Array,
  },

  emits: ["push:image", "pull:image"],

  setup(props, { emit }) {
    const imgInputRef = ref(null);

    const IMG_REQUIREMENTS = {
      minWidth: 320,
      minHeight: 320,
      maxWidth: 800,
      maxHeight: 800,
      maxSizeBytes: 20 * 1024 * 1024,
      maxAmount: 7,
    };

    // Show Finder for choosing image
    const showFileChooser = () => {
      imgInputRef.value.click();
    };

    const handleImagesInput = async (
      event,
      { minWidth, minHeight, maxWidth, maxHeight, maxSizeBytes, maxAmount }
    ) => {
      try {
        const files = Object.values(event.target.files);

        files.forEach(async (file, index) => {
          // Stop if maxAmount is reached
          if (index + 1 > maxAmount) {
            throw Error(
              `You have reached the maximum amount of images allowed for uploading, which is ${maxAmount} pcs.`
            );
          }

          try {
            const fileMimetype = file.type;
            const filename = file.name;

            // Skip if img was already added
            const isDuplicated = props.images.some((img) => {
              const filenameCurrent = getFilenameExcludingExtension(
                img.originalFilename
              );
              const filenameUploading = getFilenameExcludingExtension(filename);

              return filenameCurrent === filenameUploading;
            });
            if (isDuplicated) {
              throw new Error(
                `Image with name '${filename}' was already added.`
              );
            }

            // Prepare canvas from image file within requirements
            const imageReadyCanvas = await prepareCanvasFromImageFile(file, {
              minWidth,
              minHeight,
              maxWidth,
              maxHeight,
              maxSizeBytes,
            });

            // Get image input in base64 format (for <image>.src)
            const imageReadySrc = imageReadyCanvas.toDataURL(fileMimetype);

            // Get file of the prepared image
            const imageReadyFile = await new Promise((resolve) => {
              imageReadyCanvas.toBlob((blob) => {
                resolve(
                  new File([blob], filename, {
                    type: fileMimetype,
                  })
                );
              }, fileMimetype);
            });

            const newImage = {
              srcTemporary: imageReadySrc,
              file: imageReadyFile,
              originalFilename: filename,
              isLoading: true,
              isUploadError: false,
            };

            emit("push:image", newImage);
          } catch (error) {
            alert(error.message);
            console.log(error);
          }
        });
      } catch (error) {
        alert(error.message);
        console.log(error);
      }
    };

    const pullImage = (image) => {
      emit("pull:image", image);
    };

    return {
      imgInputRef,
      IMG_REQUIREMENTS,
      showFileChooser,
      handleImagesInput,
      pullImage,
    };
  },
};
</script>

<style scoped>
.main-photo-input {
  display: none;
}

/* .container-preview {
  width: 560px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
} */

.container-preview--columns {
  width: 560px;
  margin-bottom: -40px;

  column-count: 2;
  column-gap: 40px;

  overflow: hidden;
}
.container-preview--no-columns {
  width: 560px;

  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}
.container-preview--empty {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}
.container-preview__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  align-self: center;

  break-inside: avoid-column;
}
.container-preview__attachment-button {
  justify-self: center;
}
.container-preview__wrapper-preview {
  position: relative;
}
.wrapper-preview__img {
  width: 100%;
  height: auto;

  border-radius: 20px;
  break-inside: avoid-column;
  margin-bottom: 40px;
}
/* .wrapper-preview__img----vertical {
  width: 260px;
}
.wrapper-preview__img----horizontal {
  width: 560px;
} */
.wrapper-preview__delete-button {
  position: absolute;

  top: 10px;
  right: 10px;
  background-color: #fff;
  opacity: 0.6;
}
.wrapper-preview__delete-button:hover {
  opacity: 1;
  background-color: #fff;
}

@media (max-width: 1200px) {
  .container-preview--columns,
  .container-preview--no-columns {
    width: 100%;
    margin-bottom: 0px;

    column-count: unset;
    column-gap: unset;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    gap: 15px;
  }

  .wrapper-preview__img {
    margin-bottom: 0px;
  }
  /* .wrapper-preview__img----vertical {
    width: 100%;
  }
  .wrapper-preview__img----horizontal {
    width: 100%;
  } */
  .container-preview__wrapper-preview {
    width: 100%;
  }
  /* .container-preview__wrapper-preview--vertical {
    width: calc((100% - 15px) / 2);
  }
  .container-preview__wrapper-preview--horizontal {
    width: 100%;
  } */
}

.previews-enter-active,
.previews-leave-active {
  transition: all 0.3s;
}
.previews-enter-to,
.previews-leave-from {
  opacity: 1;
}
.previews-enter-from,
.previews-leave-to {
  opacity: 0;
}
</style>
