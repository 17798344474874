<template>
  <base-button :to="to" :disabled="disabled" class="button-cross">
    <slot name="default"><SvgIconsCross24 /></slot>
  </base-button>
</template>

<script>
import BaseButton from "@/components/UI/Base/BaseButton.vue";
import SvgIconsCross24 from "@/components/Svg/Icons/SvgIconsCross24.vue";

export default {
  components: { BaseButton, SvgIconsCross24 },
  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.button-cross {
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 20px;

  cursor: pointer;
}
.button-cross:hover {
  background-color: rgba(22, 32, 94, 0.05);
}
</style>
